var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "collapse-demo" } },
    [
      _c("collapse-default"),
      _c("collapse-accordion"),
      _c("collapse-type"),
      _c("collapse-open-hover"),
      _c("collapse-change-arrow-icon"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }