var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Open Hover", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("If you want the items to open when you hover in, add the "),
        _c("code", [_vm._v("open-hover")]),
        _vm._v(" property in the component"),
      ]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c(
            "vs-collapse",
            { attrs: { "open-hover": "" } },
            [
              _c("vs-collapse-item", [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _vm._v("Collapse item"),
                ]),
                _vm._v(
                  "\n                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque rhoncus eros tortor, non fringilla lectus cursus et. Fusce vel nisi ante. Aliquam sit amet lectus pharetra, luctus mi sed, aliquet felis. Mauris a tortor viverra, ornare tellus in, consectetur leo.\n                "
                ),
              ]),
              _c("vs-collapse-item", [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _vm._v("Collapse item 2"),
                ]),
                _vm._v(
                  "\n                    efficitur. Mauris quis faucibus nulla. Pellentesque egestas non ipsum vel maximus.\n                "
                ),
              ]),
              _c("vs-collapse-item", { attrs: { disabled: "" } }, [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _vm._v("Collapse item 3 - Disabled"),
                ]),
                _vm._v(
                  "\n                    Suspendisse aliquet condimentum diam, sed aliquam nisl dapibus et. Aliquam euismod ullamcorper dolor eu\n                "
                ),
              ]),
              _c("vs-collapse-item", [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _vm._v("Collapse item 4"),
                ]),
                _vm._v(
                  "\n\n                    Suspendisse aliquet condimentum diam, sed aliquam nisl dapibus et. Aliquam euismod ullamcorper dolor eu imperdiet. Nullam eget odio at magna gravida suscipit sed vestibulum odio. Maecenas porta elit vel lectus molestie, eget aliquam enim feugiat.\n                "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n\n  <vs-collapse open-hover>\n\n    <vs-collapse-item>\n      <div slot="header">\n        Collapse item\n      </div>\n      Lorem...metus.\n    </vs-collapse-item>\n\n    <vs-collapse-item>\n      <div slot="header">\n        Collapse item 2\n      </div>\n      Nunc...maximus.\n    </vs-collapse-item>\n\n    <vs-collapse-item disabled>\n      <div slot="header">\n        Collapse item 3 - Disabled\n      </div>\n      Suspendisse...eu\n    </vs-collapse-item>\n\n    <vs-collapse-item>\n      <div slot="header">\n        Collapse item 4\n      </div>\n      Suspendisse...finibus.\n    </vs-collapse-item>\n  </vs-collapse>\n</template>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }